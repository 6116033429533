'use client';

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import type { User } from '@/payload/payload-types';
import { useLocale } from 'next-intl';

type ResetPassword = (args: {
	password: string;
	passwordConfirm: string;
	token: string;
}) => Promise<void>;

type ForgotPassword = (args: { email: string }) => Promise<void>;

type Create = (args: {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	passwordConfirm: string;
}) => Promise<void>;

type Update = (args: { firstName: string; lastName: string; userId: string }) => Promise<void>;

type Login = (args: { email: string; password: string }) => Promise<User>;

type Logout = () => Promise<void>;

type AuthContext = {
	create: Create;
	update: Update;
	forgotPassword: ForgotPassword;
	login: Login;
	logout: Logout;
	resetPassword: ResetPassword;
	setUser: (user: User | null) => void;
	status: 'loggedIn' | 'loggedOut' | 'isFetchingUser' | undefined;
	user?: User | null;
	fetchMe: () => void;
};

const Context = createContext({} as AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode; initialUser?: User }> = ({
	children,
	initialUser,
}) => {
	const [user, setUser] = useState<User | null>(initialUser || null);
	const locale = useLocale();
	// used to track the single event of logging in or logging out
	// useful for `useEffect` hooks that should only run once
	const [status, setStatus] = useState<AuthContext['status']>(
		initialUser ? 'loggedIn' : 'isFetchingUser',
	);

	const create = useCallback<Create>(async args => {
		const res = await fetch(
			`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users?locale=${locale}`,
			{
				body: JSON.stringify({
					firstName: args.firstName,
					lastName: args.lastName,
					email: args.email,
					password: args.password,
					passwordConfirm: args.passwordConfirm,
				}),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
			},
		);

		if (res.ok) {
			const { doc, errors } = await res.json();
			if (errors) throw new Error(errors[0].message);
			const user = doc?.user;

			if (user) {
				setUser(user);
				setStatus('loggedIn');
			} else {
				throw new Error('Unexpected error');
			}
		} else {
			return res.json().then(json => {
				throw json;
			});
		}
	}, []);

	const update = useCallback<Update>(async args => {
		const res = await fetch(`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users/${args.userId}`, {
			body: JSON.stringify({
				...args,
			}),
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'PATCH',
		});

		if (res.ok) {
			const { doc: user, errors } = await res.json();

			if (errors) throw new Error(errors[0].message);

			if (user) {
				setUser(user);
			} else {
				throw new Error('Unexpected error');
			}
		} else {
			return res.json().then(json => {
				throw json;
			});
		}
	}, []);

	const login = useCallback<Login>(async args => {
		try {
			const res = await fetch(
				`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users/login?locale=${locale}`,
				{
					body: JSON.stringify({
						email: args.email,
						password: args.password,
					}),
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
				},
			);

			if (res.ok) {
				const { errors, user } = await res.json();
				if (errors) throw new Error(errors[0].message);
				setUser(user);
				setStatus('loggedIn');
				return user;
			} else {
				return res.json().then(validationErrors => {
					throw validationErrors;
				});
			}

			throw new Error('Invalid login');
		} catch (e) {
			throw new Error('An error occurred while attempting to login.');
		}
	}, []);

	const logout = useCallback<Logout>(async () => {
		try {
			const res = await fetch(
				`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users/logout?locale=${locale}`,
				{
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
				},
			);

			if (res.ok) {
				setUser(null);
				setStatus('loggedOut');
			} else {
				return res.json().then(validationErrors => {
					throw validationErrors;
				});
			}
		} catch (e) {
			throw new Error('An error occurred while attempting to logout.');
		}
	}, []);

	const fetchMe = async () => {
		try {
			setStatus('isFetchingUser');
			const res = await fetch(
				`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users/me?locale=${locale}&depth=3`,
				{
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'GET',
					cache: 'no-cache',
				},
			);

			if (res.ok) {
				const { user: meUser } = await res.json();
				setUser(meUser || null);
				setStatus(meUser ? 'loggedIn' : undefined);
			} else {
				setStatus(undefined);
				throw new Error('An error occurred while fetching your account.');
			}
		} catch (e) {
			setUser(null);
			setStatus(undefined);
			throw new Error('An error occurred while fetching your account.');
		}
	};

	useEffect(() => {
		if (!initialUser) {
			void fetchMe();
		}
	}, []);

	const forgotPassword = useCallback<ForgotPassword>(async args => {
		try {
			const res = await fetch(
				`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users/forgot-password?locale=${locale}`,
				{
					body: JSON.stringify({
						email: args.email,
					}),
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
				},
			);

			if (res.ok) {
				const { errors } = await res.json();
				if (errors) throw new Error(errors[0].message);
			} else {
				return res.json().then(validationErrors => {
					throw validationErrors;
				});
			}
		} catch (e) {
			throw new Error('An error occurred while attempting to login.');
		}
	}, []);

	const resetPassword = useCallback<ResetPassword>(async args => {
		try {
			const res = await fetch(
				`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users/reset-password?locale=${locale}`,
				{
					body: JSON.stringify({
						password: args.password,
						passwordConfirm: args.passwordConfirm,
						token: args.token,
					}),
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'POST',
				},
			);

			if (res.ok) {
				const { data, errors } = await res.json();
				if (errors) throw new Error(errors[0].message);
				setUser(data?.loginUser?.user);
				setStatus(data?.loginUser?.user ? 'loggedIn' : undefined);
			} else {
				return res.json().then(validationErrors => {
					throw validationErrors;
				});
			}
		} catch (e) {
			throw new Error('An error occurred while attempting to login.');
		}
	}, []);

	return (
		<Context.Provider
			value={{
				create,
				update,
				forgotPassword,
				login,
				logout,
				resetPassword,
				setUser,
				status,
				user,
				fetchMe,
			}}
		>
			{children}
		</Context.Provider>
	);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UseAuth<T = User> = () => AuthContext;

export const useAuth: UseAuth = () => useContext(Context);
