import { Config } from '@/payload/payload-types';
import type { CollectionSlug } from 'payload';

export const extractId = <T extends Config['collections'][CollectionSlug]>(
	objectOrID: T | T['id'],
): T['id'] => {
	if (objectOrID && typeof objectOrID === 'object') return objectOrID.id;

	return objectOrID;
};
