import { v4 } from 'uuid';

import type { OrderCartItems, User, Order, OrderParticipants } from '@/payload/payload-types';
import { extractId } from '@/shared/extract-id';
import { Flatten } from '../../_utilities/typescript';
import { generateCartOrderItemPrices } from '@/payload/utilities/generate-cart-order-item-prices';
export type CartItem = NonNullable<Flatten<OrderCartItems>>;

export type PaymentDetails = NonNullable<Order['paymentDetails']>;
export type PaymentDetail = NonNullable<Flatten<Order['paymentDetails']>>;
export type OrderParticipant = NonNullable<Flatten<OrderParticipants>>;

export type CartStateType = User['cart'] & {
	paymentDetails?: PaymentDetails;
	cartError?: string; // eg not enough spots/quantity remaining
	activeOrder?: Order | null;
	hasInitialSync?: boolean;
};
export type FlattenedCartStateType = Omit<CartStateType, 'discountCode'> & {
	discountCode?: string | null;
};
type CartAction =
	| {
			payload: CartItem;
			type: 'ADD_ITEM';
	  }
	| {
			payload: CartStateType;
			type: 'MERGE_CART';
	  }
	| {
			payload: CartStateType;
			type: 'SET_CART';
	  }
	| {
			payload: CartItem;
			type: 'DELETE_ITEM';
	  }
	| {
			type: 'CLEAR_CART';
	  }
	| {
			type: 'SET_PAYMENT_DETAILS';
			payload: NonNullable<PaymentDetails>;
	  }
	| {
			type: 'SET_ACTIVE_ORDER';
			payload?: Order | null;
	  }
	| {
			type: 'SET_DISCOUNT_CODE_TEXT';
			payload?: string | null;
	  }
	| {
			type: 'CLEAR_DISCOUNT_CODE';
	  }
	| {
			type: 'SET_CHOSEN_INSTALMENT_PAYMENTS_COUNT';
			payload: Order['chosenInstalmentPaymentsCount'];
	  }
	| {
			type: 'UPDATE_PARTICIPANT';
			payload: {
				participant: OrderParticipant;
				courseversionId: string;
			};
	  }
	| {
			type: 'SET_INITIAL_SYNC';
			payload: boolean;
	  };

const generateParticipantsToCartItems = (cartItems: OrderCartItems): OrderCartItems | null => {
	if (cartItems?.length) {
		return cartItems.map(cartItem => {
			let participants: OrderParticipants = cartItem.participants || [];
			const emptyParticipant: OrderParticipant = {
				firstName: null,
				lastName: null,
				employer: null,
				profession: null,
				email: null,
				phoneNumber: null,
				personalIdNumber: null,
				isOwnerUser: false,
			};

			if (!cartItem.quantity) {
				return {
					...cartItem,
					participants: [],
				};
			}

			const participantsCount = cartItem.participants?.length;
			if (!participantsCount) {
				participants = Array.from({ length: cartItem.quantity || 0 }, () => {
					return {
						...emptyParticipant,
						// temporary id, this will change when actually saved to payload
						id: v4(),
					};
				});
			} else if (participantsCount !== cartItem.quantity) {
				// remove participants
				const remainder = Math.abs(participantsCount - cartItem.quantity);

				if (participantsCount > cartItem.quantity) {
					participants.splice(participantsCount - remainder, remainder);
				} else if (participantsCount < cartItem.quantity) {
					// add participants
					participants = [
						...participants,
						...Array.from({ length: remainder }, () => {
							return {
								...emptyParticipant,
								// temporary id, this will change when actually saved to payload
								id: v4(),
							};
						}),
					];
				}
			}
			return {
				...cartItem,
				participants,
			};
		});
	}

	return null;
};

export const cartReducer = (state: CartStateType, action: CartAction): CartStateType => {
	switch (action.type) {
		case 'SET_CART': {
			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: action.payload.cartItems,
				discountCode: action.payload.discountCode,
				chosenInstalmentPaymentsCount: action.payload.chosenInstalmentPaymentsCount,
			});
			const cartItems = generateParticipantsToCartItems(cartItemsWithPrices);

			return {
				...action.payload,
				cartItems,
				hasInitialSync: true,
			};
		}

		case 'SET_INITIAL_SYNC': {
			return {
				...state,
				hasInitialSync: action.payload,
			};
		}

		case 'CLEAR_DISCOUNT_CODE': {
			return {
				...state,
				cartItems: generateCartOrderItemPrices({
					items: state.cartItems,
					discountCode: null,
					chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
				}),
				discountCode: null,
				discountCodeText: '',
			};
		}

		case 'MERGE_CART': {
			const { payload: incomingCart } = action;

			const existingItems = (state?.cartItems || []).filter(Boolean);
			const incomingItems = (incomingCart?.cartItems || []).filter(Boolean);

			const syncedItems = [...existingItems, ...incomingItems].reduce(
				(acc: CartItem[], cartItem) => {
					if (!cartItem.orderedCollection) {
						return acc;
					}

					// remove duplicates
					const itemId =
						typeof cartItem.orderedCollection.value === 'string'
							? cartItem.orderedCollection.value
							: cartItem?.orderedCollection?.value?.id;
					const itemType = cartItem.orderedCollection.relationTo;

					const isNotAvailableForPurchase =
						typeof cartItem?.orderedCollection.value === 'string' ||
						!cartItem?.orderedCollection.value?.isAvailableForPurchase;

					// if (isNotAvailableForPurchase) {
					// 	// logToPayload(
					// 	// 	`Cant merge courseversion ${typeof cartItem?.orderedCollection.value === 'string' ? cartItem?.orderedCollection.value : cartItem?.orderedCollection.value.id} to cart because unavailable or bad depth?`,
					// 	// );
					// 	return acc;
					// }

					const indexInAcc = acc.findIndex(_cartItem => {
						const _item = _cartItem?.orderedCollection;

						if (!_item) {
							return false;
						}

						return (
							(typeof _item.value === 'string'
								? _item.value === itemId
								: _item?.value.id === itemId) && itemType === _item.relationTo
						);
					});

					if (indexInAcc > -1) {
						const incomingItem = incomingItems.find(
							incoming =>
								extractId(incoming.orderedCollection.value) ===
								extractId(cartItem.orderedCollection.value),
						);

						let updates: Partial<CartItem> = {};
						if (incomingItem) {
							// make sure to add here incoming data that can change from server!!! eg discount code stuff
							updates = {
								priceWithoutVAT: incomingItem.priceWithoutVAT,
								price: incomingItem.price,
								VAT: incomingItem.VAT,
								discountedPriceWithoutVAT: incomingItem.discountedPriceWithoutVAT,
								discountedPrice: incomingItem.discountedPrice,
								discountedVAT: incomingItem.discountedVAT,
								discountCodeApplied: incomingItem.discountCodeApplied,
								discountedPriceReduction: incomingItem.discountedPriceReduction,
								discountedPriceReductionTotal:
									incomingItem.discountedPriceReductionTotal,
								discountedInstalmentPriceReduction:
									incomingItem.discountedInstalmentPriceReduction,
								discountCodePriceReduction: incomingItem.discountCodePriceReduction,
								participants: incomingItem.participants,
							};
						}

						acc[indexInAcc] = {
							...acc[indexInAcc],
							...updates,
							// customize the merge logic here, e.g.:
							// quantity: acc[indexInAcc].quantity + item.quantity
						};
					} else {
						acc.push(cartItem);
					}

					return acc;
				},
				[],
			);

			return {
				...state,
				discountCode: incomingCart?.discountCode || incomingCart.activeOrder?.discountCode,
				discountCodeText:
					incomingCart?.discountCodeText || incomingCart.activeOrder?.discountCodeText,
				chosenInstalmentPaymentsCount: incomingCart?.chosenInstalmentPaymentsCount,
				hasInitialSync: true,
				cartItems: syncedItems,
			};
		}

		case 'SET_PAYMENT_DETAILS': {
			const { payload: incomingPaymentDetails } = action;
			const updatedPaymentDetails = [...incomingPaymentDetails];

			return {
				...state,
				paymentDetails: updatedPaymentDetails,
			};
		}

		case 'SET_ACTIVE_ORDER': {
			const { payload: order } = action;

			return {
				...state,
				activeOrder: order,
			};
		}

		case 'UPDATE_PARTICIPANT': {
			const { payload } = action;

			if (!state.cartItems?.length) {
				return state;
			}
			const updatedCartItems = state.cartItems.map(cartItem => {
				if (extractId(cartItem.orderedCollection.value) !== payload.courseversionId) {
					return cartItem;
				}

				return {
					...cartItem,
					participants: cartItem.participants?.map(participant => {
						if (participant.id !== payload.participant.id!) {
							return participant;
						}

						return payload.participant;
					}),
				};
			});

			return {
				...state,
				cartItems: updatedCartItems,
			};
		}

		case 'ADD_ITEM': {
			// if the item is already in the cart, increase the quantity
			const { payload: incomingItem } = action;

			if (!incomingItem) {
				return state;
			}

			const indexInCart = findCartItemIndex(state, incomingItem);

			const cartItemsWithAddedItem = [...(state?.cartItems || [])];

			if (indexInCart === -1) {
				cartItemsWithAddedItem.push(incomingItem);
			}

			if (typeof indexInCart === 'number' && indexInCart > -1) {
				const incomingItemQuantity = incomingItem.quantity || 0;
				const existingItemQuantity = cartItemsWithAddedItem[indexInCart].quantity || 0;

				// replace existing quantity with incoming quantity
				cartItemsWithAddedItem[indexInCart] = {
					...cartItemsWithAddedItem[indexInCart],
					...incomingItem,
					quantity:
						(incomingItem.quantity || 0) > 0
							? incomingItemQuantity
							: existingItemQuantity,
				};
			}

			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: cartItemsWithAddedItem,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
			});

			const cartItems = generateParticipantsToCartItems(cartItemsWithPrices);

			return {
				...state,
				cartItems,
			};
		}

		case 'DELETE_ITEM': {
			const { payload: incomingItem } = action;
			const withDeletedItem = { ...state };

			const indexInCart = findCartItemIndex(state, incomingItem);

			if (typeof indexInCart === 'number' && withDeletedItem.cartItems && indexInCart > -1) {
				withDeletedItem.cartItems.splice(indexInCart, 1);
			}

			// clear cart
			if (!withDeletedItem.cartItems?.length) {
				return {
					...state,
					discountCode: null,
					discountCodeText: null,
					cartItems: [],
					activeOrder: null,
					paymentDetails: [],
				};
			}

			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: withDeletedItem.cartItems,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: state.chosenInstalmentPaymentsCount,
			});
			const cartItems = generateParticipantsToCartItems(cartItemsWithPrices);

			return {
				...state,
				cartItems,
			};
		}

		case 'CLEAR_CART': {
			return {
				...state,
				discountCode: null,
				discountCodeText: null,
				cartItems: [],
				activeOrder: null,
				paymentDetails: [],
				chosenInstalmentPaymentsCount: null,
			};
		}

		case 'SET_DISCOUNT_CODE_TEXT': {
			return {
				...state,
				discountCodeText: action.payload,
			};
		}

		case 'SET_CHOSEN_INSTALMENT_PAYMENTS_COUNT': {
			// recalculate because single instalment payment gets discounts and vice versa
			const cartItemsWithPrices = generateCartOrderItemPrices({
				items: state.cartItems,
				discountCode: state.discountCode,
				chosenInstalmentPaymentsCount: action.payload,
			});

			return {
				...state,
				cartItems: cartItemsWithPrices,
				chosenInstalmentPaymentsCount: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

function findCartItemIndex(cart: CartStateType, incomingItem: CartItem) {
	const incomingItemType = incomingItem?.orderedCollection?.relationTo;

	if (!incomingItem) {
		return -1;
	}

	return cart?.cartItems?.findIndex(({ orderedCollection }) => {
		if (!orderedCollection) {
			return false;
		}

		const incomingItemId = extractId(incomingItem.orderedCollection.value);
		return (
			extractId(orderedCollection.value) === incomingItemId &&
			incomingItemType === orderedCollection.relationTo
		);
	});
}
